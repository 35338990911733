<template>
	<div class="toC">
		<div class="header"></div>
		<div class="content">
			<div class="module module1">
				<div class="title">
					<svg-icon icon-class="tl"></svg-icon>
					<span class="text1">我们的服务</span>
					<svg-icon icon-class="tr"></svg-icon>
				</div>
				<div class="text2">
					以人才产业应用为导向，为政府协会、职业教育上下游产业、院校等各方，提供全链条服务
				</div>
				<div class="server">
					<div class="server_item">
						<div class="item_left">
							<svg-icon icon-class="xjn"></svg-icon>
						</div>
						<div class="item_right">
							<div class="right_top">学技能</div>
							<div class="right_bottom">多领域实战技能培训课程，满足学习发展需求。</div>
						</div>
					</div>
					<div class="server_item">
						<div class="item_left">
							<svg-icon icon-class="zgz"></svg-icon>
						</div>
						<div class="item_right">
							<div class="right_top">找工作</div>
							<div class="right_bottom">海量高薪职位为您精准匹配，躺着找到满意工作。</div>
						</div>
					</div>
					<div class="server_item">
						<div class="item_left">
							<svg-icon icon-class="zrc"></svg-icon>
						</div>
						<div class="item_right">
							<div class="right_top">招人才</div>
							<div class="right_bottom">便捷创建用工需求，帮助企业快速高效找到合适人才。</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="module module2">
					<div class="title">
						<svg-icon icon-class="tl"></svg-icon>
						<span class="text1">想要联系我们</span>
						<svg-icon icon-class="tr"></svg-icon>
					</div>
					<div class="text2">
						如果您想加入我们，请留下您的联系方式
					</div>
					<div class="form">
						<el-form ref="form" :model="formData" label-width="0">
							<el-form-item>
								<el-input v-model="formData.toCMobile" placeholder="请输入手机号"></el-input>
							</el-form-item>
							<el-form-item>
								<el-input v-model="formData.toCIndustry" placeholder="请输入所属行业"></el-input>
							</el-form-item>
							<el-form-item>
								<el-cascader v-model="value" :options="options" placeholder="请选择省、市、区县"></el-cascader>
							</el-form-item>

							<el-form-item>
								<div class="btn" @click="onSubmit">
									<span>提交</span>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="module module3">
					<div class="title">
						<svg-icon icon-class="tl"></svg-icon>
						<span class="text1">我们的产品</span>
						<svg-icon icon-class="tr"></svg-icon>
					</div>
					<div class="text2">
						赶快来下载APP，进一步了解我们吧
					</div>
					<div class="download">
						<div class="wrapper wrapper1">
							<div id="qrcode1">
							</div>
							<div class="notice">安卓端下载</div>
						</div>
						<div class="wrapper">
							<div id="qrcode2">
							</div>
							<div class="notice">IOS端下载</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import QRCode  from "qrcodejs2"
import { isPhone } from '../../../utils/validator'
export default {
	name: 'toC',
	data() {
		return {
			cityList: {
				province_list: {},
				city_list: {},
				county_list: {},
			},
			formData: {
				toCMobile: '',
				toCIndustry: '',
				toCAddress: '',
				source: '', //来源 1、咚咚燕 2、工信 3、应急 4、海纳易学
			},
			options: [],
            value: '',
		}
    },
	mounted() {
		if (this.$route.query.source) {
			this.formData.source = this.$route.query.source
        }
        if (this.$route.query.phone) {
			this.formData.toCMobile = this.$route.query.phone
		} else if (localStorage.getItem('userInfo')){
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.formData.toCMobile = userInfo.phone
        }
		this.getRegionList()
        this.getAndroidApk()
        this.qrcode2()
	},
	methods: {
		getRegionList() {
			this.$api.usercenter.getTree({ type: 1 }).then((res) => {
				if (res.success && res.data) {
					let filterArr = res.data
					let options = []
					filterArr.map((provice) => {
						let provinceItem = {}
						provinceItem.value = provice.name
						provinceItem.label = provice.name
						provinceItem.children = []
						provice.children.map((city) => {
							let cityItem = {}
							cityItem.value = city.name
							cityItem.label = city.name
							cityItem.children = []
							provinceItem.children.push(cityItem)
							if (city.children) {
								city.children.map((county) => {
									let countyItem = {}
									countyItem.value = county.name
									countyItem.label = county.name
									cityItem.children.push(countyItem)
								})
							}
						})
						options.push(provinceItem)
					})
					this.options = options
				}
			})
		},
		getAndroidApk() {
			axios
				.post('https://hrss-api.wdeduc.com/c/v1/client/versionInfo/findByVersionNum', {
					client: 2,
				})
				.then((res) => {
					if (res.data.success) {
						this.androidApk = res.data.data && res.data.data.upgradeAddr
                        console.log('----------', this.androidApk)
                        this.qrcode1()
					}
				})
        },
        qrcode1 () {
            if(this.QRCode1) return
            this.QRCode1 = new QRCode('qrcode1', {
                width: 160,
                height: 160,        // 高度
                text:  this.androidApk,   // 二维码内容
                display: 'none'
            })
        },
        qrcode2 () {
            if(this.QRCode2) return
            this.QRCode2 = new QRCode('qrcode2', {
                width: 160,
                height: 160,        // 高度
                text:  'https://apps.apple.com/cn/app/%E4%BC%9F%E4%B8%9C%E4%BA%91/id1616598418',   // 二维码内容
                display: 'none'
            })
        },
		onSubmit() {
			if (!isPhone.test(this.formData.toCMobile)) {
				this.$message.error('请输入正确的手机号！')
				return
			}
			if (this.value) {
				this.formData.toCAddress = this.value.join(',')
			}
			console.log('---------', this.formData)
			this.$api.editForm.saveDynamicData(this.formData).then((res) => {
				if (res.success && res.data) {
					this.$message.success('提交成功！')
				}
			})
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/toC.styl'
</style>
